<template>
  <div class="quick-toolbar">
    <div class="wrapper">
      <p class="title">什么是工作台？</p>
      <p class="desc">
        员工可以在工作台查看各阶段的客户情况并进行跟进。员工可以设置待办事项，当设定提醒时间达到时，系统会发送消息给员工。
      </p>
    </div>

    <div class="wrapper">
      <div class="mb16">
        <span class="title">如何使用？</span>
        <router-link class="ml8" :to="{name: 'system_setting_chat_toolbar'}">配置教程</router-link>
      </div>
      <p class="desc">
        配置成功后，员工登录企业微信，在与客户的聊天对话框右侧点击侧边栏的图标，即可看到工作台工具栏。
      </p>
    </div>

    <div class="wrapper">
      <a-image class="display-img"
        src="https://ese9a2b9c8d2vx-pub.pubssl.qiqiuyun.net/persondialogue/image/12590910634a887c9eead3f6f310b056"
        alt="工作台图" />
    </div>
  </div>
</template>

<script>
import { Image } from "ant-design-vue";

export default {
  name: "Workbench",
  components: {
    AImage: Image,
  },
};
</script>